import React from 'react'
//import {Link} from 'gatsby'
import Helmet from 'react-helmet'
import { OutboundLink } from 'gatsby-plugin-google-analytics'
import Layout from '../../components/Layout'
import BodyColor from '../../components/BodyColor'
import styles from './contact.module.scss'

const meansToConnect = [
  {
    link: 'mailto:belbiy@bebliy.com',
    text: 'belbiy@belbiy.com',
    className: styles.email,
  },
  {
    link: 'skype:belbiy_',
    text: 'belbiy_',
    className: styles.skype,
  },
  {
    link: 'http://linkedin.com/belbiy',
    text: 'LinkedIn',
    className: styles.linkedin,
  },
  {
    link: 'http://www.facebook.com/profile.php?id=1665386132',
    text: 'Facebook',
    className: styles.facebook,
  },
  {
    link: 'http://twitter.com/belbiy/',
    text: 'belbiy',
    className: styles.twitter,
  },
  /*
  {
    'link': 'https://plus.google.com/111858507804788538379/',
    'text': 'Google +',
    'className': 'googleplus'
  }*/
]

const accounts = [
  {
    link: 'http://dribbble.com/belbiy',
    text: 'Dribbble',
    className: styles.dribbble,
  },
  {
    link: 'https://www.behance.net/belbiy',
    text: 'Behance',
    className: styles.behance,
  },
  {
    link: 'https://www.flickr.com/photos/belbiy/',
    text: 'Flickr',
    className: styles.flickr,
  },
  {
    link: 'http://www.last.fm/user/belbiy',
    text: 'Last.fm',
    className: styles.lastfm,
  },
  /*
  {
    'link': 'http://belbiy.habrahabr.ru',
    'text': 'Habrahabr',
    'className': styles.habr
  }*/
]

const meansToConnectItems = meansToConnect.map((item, index) => (
  <li key={index} className={item.className}>
    <OutboundLink href={item.link}>
      <i />
      {item.text}
    </OutboundLink>
  </li>
))

const accountsItems = accounts.map((item, index) => (
  <li key={index} className={item.className}>
    <OutboundLink href={item.link}>
      <i />
      {item.text}
    </OutboundLink>
  </li>
))

const ContactsPage = props => (
  <Layout location={props.location}>
    <Helmet title="Contact" />
    <BodyColor isDark className={styles.pageContacts} />

    <section className={styles.pageContent}>
      <h1 className="text-center">Contact</h1>
      <p>
        I can't help you with your project now but if&nbsp;you&nbsp;have any
        comments or&nbsp;questions about this website please send me
        an&nbsp;email to{' '}
        <a href="mailto:belbiy@belbiy.com">belbiy@belbiy.com</a>.
      </p>

      <div className={styles.columns}>
        <div className={styles.column}>
          <h2>Connect</h2>
          <ul className={styles.contactsList}>{meansToConnectItems}</ul>
        </div>

        <div className={styles.column}>
          <h2>I'm on</h2>
          <ul className={styles.contactsList}>{accountsItems}</ul>
        </div>
      </div>
    </section>

    <div className={styles.earth}>
      <div>
        <div />
      </div>
    </div>
  </Layout>
)

export default ContactsPage
